import React from "react";

import { Skeleton, Typography, useTheme } from "@mui/material";

import { VerticalTimelineElement } from "react-vertical-timeline-component";
import 'react-vertical-timeline-component/style.min.css';

import CollapsibleContainer from "../../common/containers/CollapsibleContainer";
import MoreInfoButton from "../../common/buttons/MoreInfoButton";
import ExpandingArrow from "../../common/buttons/ExpandingArrow";
import WhiteDivider from "../../common/other/WhiteDivider";

import { useViewport } from "../../../hooks/useViewport";
import { useLiterals } from "../../../hooks/useLiterals";

import { getIconFromName } from "../../../utils/iconUtils";
import { getWorkLiterals } from "../../../utils/literalUtils";

const WorkTimelineElement = (data, id) => {
    const [showInfo, setShowInfo] = React.useState(false);

    const theme = useTheme();

    // #region Vertical timeline element styling

    const contentStyle = { 
        background: 
        theme.palette.primary.main, 
        color: theme.palette.common.white,
        boxShadow: `0 3px 0 ${theme.palette.primary.light}`
    };

    const contentArrowStyle = { 
        borderRight: `7px solid ${theme.palette.primary.main}`
    }

    const iconStyle = { 
        background: theme.palette.primary.main, 
        color: theme.palette.common.white, 
        boxShadow: `0 0 0 4px ${theme.palette.primary.light},inset 0 2px 0 rgba(0,0,0,.08),0 3px 0 4px rgba(0,0,0,.05)`
    }

    // #endregion Vertical timeline element styling

    return (
        <VerticalTimelineElement 
            key={id}
            date={data && data.date}
            dateClassName={`rubik-font custom-date custom-date-${theme.palette.mode}`}
            contentStyle={contentStyle}
            contentArrowStyle={contentArrowStyle}
            iconStyle={iconStyle}
            icon={data && getIconFromName(data.icon)}
        >
            <WorkTimelineElementHeader data={data} showInfo={showInfo} setShowInfo={setShowInfo} />
            {
                data &&
                <CollapsibleContainer expanded={showInfo}>
                    <WorkTimelineElementInfo data={data} />
                </CollapsibleContainer>
            }
            
        </VerticalTimelineElement>
    );    
}

const WorkTimelineElementHeader = ({data, showInfo, setShowInfo}) => {
    const theme = useTheme();

    const BusinessAndRole = () => {
        return (
            <>
            {
                data ?
                <div>
                    <Typography variant="h6">{data.company}</Typography>
                    <Typography variant="subtitle1">{data.role}</Typography>
                </div> :
                <Skeleton width="100%" height="5rem" />
            }
            </>
        )
    }

    return (
        <div className="d-flex flex-row jc-space-between ai-center">
            <BusinessAndRole />
            {
                data &&
                <ExpandingArrow size="large" color={theme.palette.common.white} expanded={showInfo} setExpanded={setShowInfo} />
            }
        </div>
    )
}

const WorkTimelineElementInfo = ({data}) => {
    const literals = getWorkLiterals(useLiterals());

    const gridClass = useViewport({
        xs: "grid-1col-auto gap-0_5rem",
        sm: "grid-2col-auto gap-1rem"
    })

    const InfoLine = ({item, text}) => {
        return (
            <>
                <Typography className="bold-text">
                    {item}
                </Typography>
                <Typography>
                    {text}
                </Typography>
            </>
        );
    }

    return (
        <div className="custom-content d-flex flex-column gap-1rem pb-0_5">
            <div className="d-flex flex-column gap-0_5rem">
                <WhiteDivider variant="middle" component="p"/>
                <Typography variant="subtitle2">{data.description}</Typography>
                <div className={`d-inline-grid ${gridClass} ai-center`}>
                    <InfoLine
                        item={literals && literals.projects}
                        text={data.projects}
                    />
                    <InfoLine
                        item={literals && literals.functions}
                        text={data.functions}
                    />
                    <InfoLine
                        item={literals && literals.techs}
                        text={data.techs}
                    />
                </div>
            </div>
            <div className="d-flex flex-row jc-center">
                <MoreInfoButton route={`/project/${data.route}`} />
            </div>
        </div>
    );
}

export default WorkTimelineElement;