import { 
    Card, 
    CardContent, 
    CardActionArea,
    CardActions, 
    CardMedia,
    Typography,
    Divider,
    Tooltip,
    Skeleton
} from "@mui/material";
import { useLocation } from "wouter";

import RedirectionalIconButton from "../../common/buttons/RedirectionalIconButton";

import { useLiterals } from "../../../hooks/useLiterals";
import { useProgressiveImage } from "../../../hooks/useProgressiveImage";

import { getProjectsLiterals } from "../../../utils/literalUtils";
import { getPublicAsset, processRichText } from "../../../utils/utils";

const ProjectItem = ({projectData, projectMedia}) => {
    const literals = getProjectsLiterals(useLiterals());

    const [, setLocation] = useLocation();

    const onClick = () => {
        setLocation(`/project/${projectData.route}`);
    }

    // #region Inner components

    const ProjectImage = () => {
        const projImage = useProgressiveImage(projectMedia ? getPublicAsset(projectMedia.backgroundImage) : undefined);
        const imageStyle = { height: 140 };

        return (
            <>
                {
                    projectMedia
                    ?
                        projectMedia.backgroundImage &&
                        projImage ?
                        <CardMedia
                            sx={imageStyle}
                            image={projImage} />
                        :
                        <Skeleton sx={imageStyle} />
                    :
                    <Skeleton sx={imageStyle} />
                }
            </>
        )
    }

    const ProjectContent = () => {
        return (
            <CardContent sx={{ paddingBottom: "0.25rem" }}>
                {
                    projectData ?
                    <div className="d-flex flex-column gap-0_25rem">
                        <Typography variant="body2" fontWeight={700}>{projectData.name}</Typography>
                        <Divider />
                        <Typography variant="body2">{processRichText(projectData.description)}</Typography>
                    </div> :
                    <Skeleton width="100%" height="12rem" />
                }
            </CardContent>
        )
    }

    const ProjectLinks = () => {
        return (
            <CardActions sx={{ justifyContent: "center", paddingTop: "0.25rem"}}>
                {
                    projectData && projectData.links && projectData.links.map((link, id) => <RedirectionalIconButton key={id} link={link} />)
                }
            </CardActions>
        )
    }

    // #endregion Inner components

    return (
        <Card elevation={4} sx={{ minWidth: 200, maxWidth: 300 }}>
            <Tooltip title={literals && literals.tooltip} placement="right">
                <CardActionArea onClick={projectData && onClick}>
                    <ProjectImage />
                    <ProjectContent />
                </CardActionArea>
            </Tooltip>
            <ProjectLinks />
        </Card>
    );
}

export default ProjectItem;