import { createContext } from "react";
import { useJson } from "../hooks/useJson";
import { useLanguage } from "../hooks/useLanguage";

export const LiteralsContext = createContext();

export const LiteralsProvider = ({ children }) => {
    const { language } = useLanguage();

    const data = useJson(`literals-${language}`);

    return (
        <>
            {
                <LiteralsContext.Provider value={data}>
                    {children}
                </LiteralsContext.Provider>
            }
        </>
    )
}