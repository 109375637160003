import SplitContainer from "../../common/containers/SplitContainer";
import CertificationItem from "./CertificationItem";

import { useDataContext } from "../../../hooks/useDataContext";
import { useMediaContext } from "../../../hooks/useMediaContext";
import { useLiterals } from "../../../hooks/useLiterals";

import { getEducationLiterals } from "../../../utils/literalUtils";
import { CERTIFICATION_PLACEHOLDER_LIST_COUNT } from "../../../utils/constants";

const CertificationList = () => {
    const globalData = useDataContext();
    const certData = globalData && globalData.education;
    
    const globalMedia = useMediaContext();
    const certMedia = globalMedia && globalMedia.education;
    
    const placeholderList = Array(CERTIFICATION_PLACEHOLDER_LIST_COUNT).fill(undefined);
    const mapCertItem = (item, id) => <CertificationItem key={id} certData={item} certMedia={certMedia ? certMedia.certList[id] : undefined} />

    return (
        <div className="d-flex flex-column gap-1rem">
            {
                certData ?
                certData.certList.map(mapCertItem) :
                placeholderList.map(mapCertItem)
            }
        </div>
    )
}

const Certifications = () => {
    const literals = getEducationLiterals(useLiterals());

    const bodyData = {
        size: 1,
        component: <CertificationList />
    }

    return (
        <SplitContainer
            title={literals && literals.title}
            showTitle={true}
            bodyData={bodyData} />
    );
}

export default Certifications;