import { Add, Close, Remove, RestartAlt } from "@mui/icons-material";
import { styled } from "@mui/material";

import { TransformComponent, TransformWrapper, useControls } from "react-zoom-pan-pinch";

import FloatingButtonSet from "../../common/buttons/FloatingButtonSet";
import CircularLoading from "../../common/other/CircularLoading";

import { useViewport } from "../../../hooks/useViewport";
import { useLiterals } from "../../../hooks/useLiterals";
import { useProgressiveImage } from "../../../hooks/useProgressiveImage";

import { ICON_SIZES } from "../../../utils/constants";
import { getPublicAsset } from "../../../utils/utils";
import { getImageViewerLiterals } from "../../../utils/literalUtils";

// #region Styled components

const ImageViewerContainer = styled('div')(() => ({
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 10,

    width: "100%",
    height: "100%",

    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    backgroundColor: "rgba(0,0,0,0.85)"
}));

const ClosingBackground = styled('div')(() => ({
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: -1,
    width: "100%",
    height: "100%"
}));

// #endregion Styled components

const Controls = ({onClickClose}) => {
    const literals = getImageViewerLiterals(useLiterals());
    const iconSize = useViewport(ICON_SIZES);

    const { zoomIn, zoomOut, resetTransform } = useControls();

    const zoomButtons = [
        {
            component: <Add fontSize={iconSize} />,
            onClick: () => zoomIn(),
            tooltip: literals.zoomIn
        },
        {
            component: <Remove fontSize={iconSize} />,
            onClick: () => zoomOut(),
            tooltip: literals.zoomOut
        },
        {
            component: <RestartAlt fontSize={iconSize} />,
            onClick: () => resetTransform(),
            tooltip: literals.reset
        }
    ];

    const closeButton = [
        {
            component: <Close fontSize={iconSize} />,
            onClick: () => onClickClose(),
            tooltip: literals.close
        }
    ]

    const zoomButtonsPosition = useViewport({
        xs: "top-left",
        sm: "top"
    })

    return (
        <>
            <FloatingButtonSet buttons={zoomButtons} orientation="horizontal" position={zoomButtonsPosition} paddingClass="px-2 py-2" />
            <FloatingButtonSet buttons={closeButton} orientation="horizontal" position="top-right" paddingClass="px-2 py-2" />
        </>
    )
}

const ImageViewer = ({imageUrl, imageAlt, setImageViewerOpen}) => {
    const literals = getImageViewerLiterals(useLiterals());
    const loadedImage = useProgressiveImage(imageUrl ? getPublicAsset(imageUrl) : undefined);
    
    const closeViewer = () => {
        setImageViewerOpen(false);
    }

    return (
        <ImageViewerContainer>
            <TransformWrapper
                minScale={0.75}
                initialScale={1}
                initialPositionX={0}
                initialPositionY={0}
                centerZoomedOut={false}>
                    {
                        ({zoomIn, zoomOut, resetTransform, ...rest}) => (
                            <>
                                <Controls onClickClose={closeViewer} />
                                <TransformComponent wrapperStyle={{ overflow: 'visible' }}>
                                    {
                                        loadedImage ?
                                        <img 
                                            src={imageUrl} 
                                            alt={imageAlt ? imageAlt : literals.imageAltPlaceholder} 
                                            width="100%" 
                                            height="auto" 
                                        /> :
                                        <CircularLoading />
                                    }
                                </TransformComponent>
                                <ClosingBackground onClick={closeViewer} />
                            </>
                        )
                    }
            </TransformWrapper>
        </ImageViewerContainer>
    )
}

export default ImageViewer;