import { useState } from "react";

import { Paper, Skeleton, styled, Tooltip, Typography } from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";

import LeftBorderContainer from "../../common/containers/LeftBorderContainer";
import TransparentIconButton from "../../common/buttons/TransparentIconButton";
import YoutubeEmbed from "../../common/other/YoutubeEmbed";
import ImageViewer from "./ImageViewer";
import CircularLoading from "../../common/other/CircularLoading";

import { useViewport } from "../../../hooks/useViewport";
import { useLiterals } from "../../../hooks/useLiterals";
import { useProgressiveImage } from "../../../hooks/useProgressiveImage";

import { getPublicAsset } from "../../../utils/utils";
import { ICON_SIZES } from "../../../utils/constants";
import { getProjectPageLiterals } from "../../../utils/literalUtils";

//#region Styled components

const MediaPaper = styled(Paper)(({theme}) => ({
    position: "relative",
    boxSizing: "border-box",
    height: "100%",
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.paper : theme.palette.primary.light,
    "&.MuiPaper-root": 
    {
        paddingBottom: 0
    }
}));

const ImageContainer = styled('div')(({imageUrl, active}) => ({
    position: "absolute",
    top: 0,
    left: 0,
    
    backgroundImage: `url(${imageUrl})`,
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",

    transition: "opacity 1s ease-in-out",
    opacity: active ? 1 : 0,

    height: "100%",
    width: "100%",

    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    alignItems: "center",

    pointerEvents: active ? 'all' : 'none'
}));

const VideoContainer = styled('div')(({active}) => ({
    transition: "opacity 1s ease-in-out",
    opacity: active ? 1 : 0,

    height: "100%",
    width: "100%",

    pointerEvents: active ? 'all' : 'none'
}));

const CaptionContainer = styled('div')(({theme}) => ({
    backgroundColor: "rgba(0,0,0,0.6)",
    height: "40px",
    color: theme.palette.common.white,
    width: "80%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "0.5rem",
    boxSizing: "border-box"
}));

const FloatingContainer = styled('div')(({leftDirection, width}) => ({
    position: "absolute",
    top: 0,
    bottom: 0,
    left: leftDirection ? 0 : undefined,
    right: !leftDirection ? 0 : undefined,
    width: width,
    zIndex: 2,

    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    pointerEvents: "none"
}));

const VignetteBackground = styled(FloatingContainer, {
    shouldForwardProp: (prop) => prop !== "leftDirection"
})(({theme, leftDirection}) => ({
    backgroundImage: 
        leftDirection ? 
        theme.palette.background.leftVignette :
        theme.palette.background.rightVignette,
    mixBlendMode: "multiply",
    zIndex: 1
}));

//#endregion Styled components

const MediaCarousel = ({media, captions}) => {

    const [mediaPointer, setMediaPointer] = useState(0);
    const [imageViewerOpen, setImageViewerOpen] = useState(false);

    //#region Inner components

    const CarouselControl = () => {
        
        const literals = getProjectPageLiterals(useLiterals());
    
        const iconSize = useViewport(ICON_SIZES);
        const floatingContainerWidth = useViewport({
            xs: "50px",
            sm: "60px",
            md: "80px"
        });
    
        function onClickArrowButton(leftDirection) {
            if(!media) return;
    
            if(leftDirection)
            {
                if(mediaPointer === 0)
                {
                    setMediaPointer(media.length - 1);
                    return;
                }
                setMediaPointer(mediaPointer - 1);
            }
            else
            {
                if(mediaPointer === media.length - 1)
                {
                    setMediaPointer(0);
                    return;
                }
                setMediaPointer(mediaPointer + 1);
            }
        }
    
        return (
            <>
                <FloatingContainer leftDirection={true} width={floatingContainerWidth}>
                    <Tooltip title={literals && literals.previousImage}>
                        <TransparentIconButton onClick={() => onClickArrowButton(true)}>
                            <KeyboardArrowLeft fontSize={iconSize} />
                        </TransparentIconButton>
                    </Tooltip>
                </FloatingContainer>
    
                <FloatingContainer leftDirection={false} width={floatingContainerWidth}>
                    <Tooltip title={literals && literals.nextImage}>
                        <TransparentIconButton onClick={() => onClickArrowButton(false)}>
                            <KeyboardArrowRight fontSize={iconSize}/>
                        </TransparentIconButton>
                    </Tooltip>
                </FloatingContainer>
    
                <VignetteBackground leftDirection={true} width={floatingContainerWidth} />
                <VignetteBackground leftDirection={false} width={floatingContainerWidth}/>
            </>
        )
    }
    
    const MediaElement = ({mediaElement, caption, index}) => {
        if(!mediaElement)
        {
            return <MediaPlaceholder />
        }
    
        if(mediaElement.type === 'video')
        {
            return <Video index={index} video={mediaElement} />
        }
    
        return <Image index={index} image={mediaElement} caption={caption} />
    }
    
    const MediaPlaceholder = () => {
        return (
            <Skeleton width="100%" height="100%" animation="wave" sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                <CircularLoading />
            </Skeleton>
        )
    }
    
    const Image = ({image, caption, index}) => {
        const imageUrl = useViewport(image && image.url);
        const loadedImage = useProgressiveImage(imageUrl ? getPublicAsset(imageUrl) : undefined);

        const onClickImage = () => {
            setImageViewerOpen(true);
        }
    
        return (
            <>
                {
                    loadedImage ?
                    <ImageContainer onClick={onClickImage}
                        imageUrl={loadedImage} active={index === mediaPointer}>
                            <CaptionContainer>
                                <Typography noWrap variant="subtitle2">
                                    {caption ? caption : <Skeleton />}
                                </Typography>
                            </CaptionContainer>
                    </ImageContainer>
                    :
                    <MediaPlaceholder />
                }
            </>
        )
    }
    
    const Video = ({video, index}) => (
        <VideoContainer active={index === mediaPointer}>
            <YoutubeEmbed embedId={video.embedId} LoadingPlaceholder={() => <MediaPlaceholder />} />
        </VideoContainer>
    )
    
    //#endregion Inner components

    const mediaHeight = useViewport({
        xs: "200px",
        sm: "320px",
        md: "480px",
        lg: "600px",
        xl: "720px"
    });

    return (
        <>
            {
                media && imageViewerOpen && 
                <ImageViewer 
                    imageUrl={media[mediaPointer].imageViewerUrl} 
                    imageAlt={captions && captions[mediaPointer]}
                    setImageViewerOpen={setImageViewerOpen}
                />
            }
            <LeftBorderContainer>
                <div style={{ position: "relative", height: mediaHeight}}>
                    {
                        media && captions && media.length > 1 &&
                        <CarouselControl />
                    }
                    <MediaPaper sx={{ padding: "1rem", height: "100%"}}>
                        {
                            media ?
                            <MediaElement mediaElement={media[mediaPointer]} caption={captions && captions[mediaPointer]} index={mediaPointer} /> :
                            <MediaPlaceholder />
                        }
                    </MediaPaper>
                </div>
            </LeftBorderContainer>
        </>
    );
}

export default MediaCarousel;