import { useState } from "react";
import { useLocation } from "wouter";
import { useTheme } from "@mui/material";

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import SortIcon from '@mui/icons-material/Sort';
import { DarkMode, LightMode } from "@mui/icons-material";

import FloatingButtonSet from "../buttons/FloatingButtonSet";
import DarkModeToggle from "../../common/buttons/DarkModeToggle";

import { useViewport } from "../../../hooks/useViewport";
import { useLiterals } from "../../../hooks/useLiterals";

import { getIconFromName } from "../../../utils/iconUtils";
import { getOtherLiterals } from "../../../utils/literalUtils";
import { ICON_SIZES } from "../../../utils/constants";

const DrawerButton = ({onClick}) => {
    const literals = getOtherLiterals(useLiterals());
    const iconSize = useViewport(ICON_SIZES);

    const button = [
        {
            component: <SortIcon fontSize={iconSize} />,
            onClick: onClick,
            tooltip: literals && literals.showNav
        }
    ];

    const paddingClass = {
        xs: "py-6 px-2",
        md: "px-2 py-4"
    };

    return (
        <FloatingButtonSet buttons={button} position="bottom-left" paddingClass={paddingClass} />
    );
}

const DrawerContent = ({navElements, setOpen, scrollNavigation}) => {
    const [, setLocation] = useLocation();
    const theme = useTheme();
    const iconSize = useViewport(ICON_SIZES);

    // #region Inner components

    const DrawerList = () => {
        return (
            <List>
            {   
                navElements &&
                navElements.map((element, index) => <DrawerListElement key={index} element={element} />) 
            }
            </List>
        )
    }

    const DrawerListElement = ({element}) => {
        const onClickDrawerItem = (element) => () => {
            setOpen(false);

            if(scrollNavigation)
            {
                const visualElement = document.getElementById(element.id);
                visualElement.scrollIntoView({behavior: "smooth"});
            }
            else
            {
                setLocation(`/project/${element.id}`);
            }
        }

        if(element.title !== undefined)
        {
            return (
                <ListItem disablePadding onClick={onClickDrawerItem(element)}>
                    <ListItemButton>
                        <ListItemIcon sx={{ color: theme.palette.icon.main }}>
                            <div style={{ fontSize: "24px" }}>
                                {getIconFromName(element.icon)}
                            </div>
                        </ListItemIcon>
                        <ListItemText primary={element.title} />
                    </ListItemButton>
                </ListItem>
            )
        }
        return undefined;
    } 

    const ThemeModeToggle = () => {
        return (
            <div className="d-flex flex-row ai-center jc-center py-2"
                style={{ color: theme.palette.icon.main }}>
                <LightMode fontSize={iconSize} />
                <DarkModeToggle />
                <DarkMode fontSize={iconSize} />
            </div>
        )
    }

    // #endregion Inner components

    return (
        <Box sx={{ width: 250, height: '100%' }} role="presentation">
            <div className="d-flex flex-column jc-space-between full-height">
                <DrawerList />
                <ThemeModeToggle />
            </div>
        </Box>
    )
}

const NavDrawer = ({navElements, scrollNavigation}) => {
    const [open, setOpen] = useState(false);
    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    }

    return (
        <>
            <DrawerButton onClick={toggleDrawer(true)} />
            <Drawer open={open} onClose={toggleDrawer(false)}>
                <DrawerContent 
                    navElements={navElements}
                    setOpen={setOpen}
                    scrollNavigation={scrollNavigation}
                />
            </Drawer>
        </>
    );
}

export default NavDrawer;