import { useEffect, useState } from "react"

export const useJson = (filename) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        import(`../json/${filename}.json`)
            .then((res) => {
                setData(res.default);
            })
            .catch(_ => {
                setData(null);
            })
    }, [filename]);

    return data;
}