import ProjectItem from "./ProjectItem"
import SplitContainer from "../../common/containers/SplitContainer";

import { useDataContext } from "../../../hooks/useDataContext";
import { useLiterals } from "../../../hooks/useLiterals";
import { useMediaContext } from "../../../hooks/useMediaContext";

import { getProjectsLiterals } from "../../../utils/literalUtils";
import { PROJECTS_PLACEHOLDER_LIST_COUNT } from "../../../utils/constants";

const ProjectList = () => {
    const globalData = useDataContext();
    const projectData = globalData && globalData.projects;

    const globalMedia = useMediaContext();
    const projectMedia = globalMedia && globalMedia.projects;

    const placeholderList = Array(PROJECTS_PLACEHOLDER_LIST_COUNT).fill(undefined);
    const mapProjItem = (item, id) => <ProjectItem key={id} projectData={item} projectMedia={projectMedia ? projectMedia[id] : undefined} />

    return (
        <div className="project-display">
            {
                projectData ?
                projectData.map(mapProjItem) :
                placeholderList.map(mapProjItem)
            }
        </div>
    )
}

const Projects = () => {
    const literals = getProjectsLiterals(useLiterals());

    const bodyData = {
        size: 1,
        component: <ProjectList />
    }

    return (
        <SplitContainer
            title={literals && literals.title} 
            showTitle={true}
            bodyData={bodyData}
        />
    )
}

export default Projects;