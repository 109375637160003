import { Link, Skeleton, Typography, useTheme } from "@mui/material";

import LazyImage from "../../common/other/LazyImage";

import { useViewport } from "../../../hooks/useViewport";
import { useLiterals } from "../../../hooks/useLiterals";
import { useMediaContext } from "../../../hooks/useMediaContext";

import { getPublicAsset } from "../../../utils/utils";
import { getIntroductionLiterals } from "../../../utils/literalUtils";

const Photo = () => {
    const globalMedia = useMediaContext();
    const introMedia = globalMedia && globalMedia.intro;

    const imageSize = useViewport({
        xs: { width: "9rem", height: "9rem" },
        sm: { width: "4.5rem", height: "4.5rem" },
        md: { width: "9rem", height: "9rem" },
    });

    const meImage = useViewport(introMedia ? introMedia.me : {});

    const MeImageSkeleton = () => {
        return (
            <Skeleton variant="circular" sx={imageSize} />
        )
    }

    return (
        <>
            {
                introMedia ?
                <LazyImage src={getPublicAsset(meImage)} alt="me" sx={imageSize} LoadingPlaceholder={MeImageSkeleton} /> :
                <MeImageSkeleton />
            }
        </>
    )
}

const Name = () => {
    const literals = getIntroductionLiterals(useLiterals());

    const theme = useTheme();

    const textVariant = useViewport({
        xs: "h6",
        md: "subtitle1"
    });

    return (
        <>
        {
            literals ?
            <div className="d-flex flex-column ai-center gap-0_25rem">
                <Typography variant={textVariant} className="text-align-center" fontWeight={500}>{literals.name}<br/>{literals.surname}</Typography>
                <Typography variant={textVariant} className="text-align-center" fontWeight={500}>
                    <Link color={theme.palette.primary.contrastText} href={`mailto:${literals.contact}`}>{literals.contact}</Link>
                </Typography>
            </div> :
            <Skeleton width="100%" height="6rem" />
        }
        </>
    )
}

const PhotoAndName = () => {
    const containerClass = useViewport({
        xs: "flex-column",
        sm: "flex-row",
        md: "flex-column"
    });

    return (
        <div className={`d-flex ${containerClass} jc-center ai-center gap-1rem`}>
            <Photo />
            <Name />
        </div>
    );
}

export default PhotoAndName;