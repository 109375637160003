import { createTheme, responsiveFontSizes } from "@mui/material";

const commonSettings = {
    typography: {
        fontWeightLight: 400,
        fontWeightRegular: 500,
        fontWeightMedium: 600,
        fontWeightBold: 800,

        h1: {
            fontFamily: "Rubik, serif"
        },
        h2: {
            fontFamily: "Rubik, serif"
        },
        h3: {
            fontFamily: "Rubik, serif"
        },
        h4: {
            fontFamily: "Rubik, serif"
        },
        h5: {
            fontFamily: "Rubik, serif"
        },
        h6: {
            fontFamily: "Rubik, serif"
        },
        subtitle1: {
            fontFamily: "Rubik, serif",
            fontSize: '0.875rem',
            '@media (min-width:600px)': {
                fontSize: '1rem'
            }
        },
        subtitle2: {
            fontFamily: "Rubik, serif",
            fontSize: '0.75rem',
            '@media (min-width:600px)': {
                fontSize: '0.875rem'
            }
        },
        body1: {
            fontFamily: "Nunito, sans-serif",
            fontSize: '0.875rem',
            '@media (min-width:600px)': {
                fontSize: '1rem'
            }
        },
        body2: {
            fontFamily: "Nunito, sans-serif",
            fontSize: '0.75rem',
            '@media (min-width:600px)': {
                fontSize: '0.875rem'
            }
        }
    }
};

const lightThemePalette = {
    mode: "light",
    primary: {
        lighter: '#eefbf6',
        light: '#afebd2',
        main: '#22a782',
        dark: '#106c57',
        darker: '#0e463a',
        contrastText: '#0e463a'
    },
    secondary: {
        lighter: '#f9d2d8',
        light: '#ed7f93',
        main: '#cd3158',
        dark: '#912043',
        darker: '#450c1e'
    },
    background: {
        paper: 'white',
        base: '#eefbf6',
        verticalVignette: 'linear-gradient(rgb(180,180,180) 0%, rgba(0,0,0,0) 10%, rgba(0,0,0,0) 90%, rgb(180,180,180) 100%);',
        leftVignette: 'linear-gradient(90deg, rgb(150,150,150) 0%, rgba(0,0,0,0) 100%)',
        rightVignette: 'linear-gradient(90deg, rgba(0,0,0,0) 0%, rgb(150,150,150) 100%)'
    },
    button: {
        base: 'white',
        hover: 'white'
    }
}

let lightTheme = createTheme({
    palette: {
        ...lightThemePalette,
        icon: {
            main: lightThemePalette.primary.main
        }
    }
}, commonSettings);
lightTheme = responsiveFontSizes(lightTheme);

const darkThemePalette = {
    mode: "dark",
    primary: {
        lighter: '#e97c26',
        light: '#da631c',
        main: '#903d1c',
        dark: '#3f180b',
        darker: '#3f180b',
        contrastText: '#ffffff'
    },
    secondary: {
        lighter: '#2195b9',
        light: '#2195b9',
        main: '#1c6f90',
        dark: '#225168',
        darker: '#102c3c'
    },
    background: {
        paper: '#121212',
        base: '#343434',
        verticalVignette: 'linear-gradient(rgb(100,100,100) 0%, rgba(0,0,0,0) 10%, rgba(0,0,0,0) 90%, rgb(100,100,100) 100%);',
        leftVignette: 'linear-gradient(90deg, rgb(150,150,150) 0%, rgba(0,0,0,0) 100%)',
        rightVignette: 'linear-gradient(90deg, rgba(0,0,0,0) 0%, rgb(150,150,150) 100%)'
    },
    button: {
        base: 'black',
        hover: 'rgb(50, 50, 50)'
    }
}

let darkTheme = createTheme({
    palette: {
        ...darkThemePalette,
        icon: {
            main: darkThemePalette.primary.light
        }
    }
}, commonSettings);
darkTheme = responsiveFontSizes(darkTheme);

const customThemes = { lightTheme, darkTheme };

export default customThemes;