import { CircularProgress } from "@mui/material"

import { useViewport } from "../../../hooks/useViewport";

const CircularLoading = () => {
    const circleSize = useViewport({
        xs: "1.5rem",
        sm: "2rem",
        md: "2.5rem",
        lg: "3rem",
        xl: "4rem"
    });

    return (
        <CircularProgress color="secondary" sx={{visibility: "visible"}} size={circleSize} />
    )
}

export default CircularLoading;