import { styled } from "@mui/material";

import { useViewport } from "../../../hooks/useViewport";

const ShadowContainer = styled('div')(({theme, borderRadius}) => ({
    boxShadow: theme.shadows[4],
    borderRadius: borderRadius,
    overflow: "hidden",
    height: '100%'
}))

const BasicContainer = ({children}) => {
    const borderRadius = useViewport({
        xs: "0px",
        sm: "10px"
    });

    return (
        <ShadowContainer borderRadius={borderRadius}>
            {children}
        </ShadowContainer>
    )
}

export default BasicContainer;