import { useLocation } from "wouter";
import { Home } from "@mui/icons-material";

import FloatingButtonSet from "./FloatingButtonSet";

import { useLiterals } from "../../../hooks/useLiterals";
import { useViewport } from "../../../hooks/useViewport";

import { getOtherLiterals } from "../../../utils/literalUtils";
import { ICON_SIZES } from "../../../utils/constants";

const HomeButton = () => {
    const literals = getOtherLiterals(useLiterals());
    const iconSize = useViewport(ICON_SIZES);

    const [, setLocation] = useLocation();

    const onClickFloatingButton = () => {
        setLocation("/");
    }

    const button = [
        {
            component: <Home fontSize={iconSize} />,
            onClick: onClickFloatingButton,
            tooltip: literals && literals.homeButtonTooltip
        }
    ];

    const paddingClass = useViewport({
        xs: "p-2",
        sm: "px-2 py-4"
    });

    return (
        <FloatingButtonSet buttons={button} paddingClass={paddingClass} />
    )
}

export default HomeButton;