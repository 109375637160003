import { useProgressiveImage } from "../../../hooks/useProgressiveImage";

const LazyImage = ({src, alt, className, sx, LoadingPlaceholder}) => {
    const loaded = useProgressiveImage(src);

    const imageStyle = !loaded ? { display: "none" } : {}

    return (
        <>
            {
                
                <img src={src} alt={alt} className={className} style={{...sx, ...imageStyle}} />
            }
            {
                !loaded &&
                <LoadingPlaceholder />
            }
        </>
    )
}

export default LazyImage;