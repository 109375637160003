import { Skeleton, Typography } from "@mui/material";

import HomeButton from "../../common/buttons/HomeButton"
import SplitContainer from "../../common/containers/SplitContainer"

import { useLiterals } from "../../../hooks/useLiterals";

import { getErrorLiterals } from "../../../utils/literalUtils";

const Body = ({text}) => {
    return (
        <Typography variant="h6">{text ? text : <Skeleton />}</Typography>
    );
}

const ErrorLayout = () => {
    const literals = getErrorLiterals(useLiterals());

    const bodyData = {
        size: 12,
        component: <Body text={literals && literals.pageNoFound} />
    }

    return (
        <>
            <HomeButton />
            <SplitContainer 
                title={literals && literals.error404}
                showTitle={true}
                bodyData={bodyData}
            />
        </>
    )
}

export default ErrorLayout;