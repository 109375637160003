import Body from "../components/errorPage/layout/ErrorLayout";
import Footer from "../components/common/footer/Footer";

const Error404 = () => {

    return (
        <>
            <Body />
            <Footer />
        </>
    )
}

export default Error404;