const CollapsibleContainer = ({children, expanded}) => {
    return (
        <div className={expanded ? "collapsible active" : "collapsible"}>
            <div>
                {children}
            </div>
        </div>
    )
}

export default CollapsibleContainer;