import { styled } from "@mui/material";

import BasicContainer from "./BasicContainer";

import { useViewport } from "../../../hooks/useViewport";

const CustomContainer = styled('div')(({theme, borderWidth}) => ({
    borderLeft: `${borderWidth} solid ${theme.palette.primary.main}`,
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
}));

const LeftBorderContainer = ({children}) => {
    const borderWidth = useViewport({
        xs: '0px',
        sm: '7px'
    });

    return (
        <BasicContainer>
            <CustomContainer borderWidth={borderWidth}>
                {children}
            </CustomContainer>
        </BasicContainer>
    )
}

export default LeftBorderContainer;