import WorkTimeline from "./WorkTimeline";
import SplitContainer from "../../common/containers/SplitContainer";

import { useDataContext } from "../../../hooks/useDataContext";
import { useLiterals } from "../../../hooks/useLiterals";

import { getWorkLiterals } from "../../../utils/literalUtils";
import { WORK_EXPERIENCE_PLACEHOLDER_LIST_COUNT } from "../../../utils/constants";

const WorkExperience = () => {
    const literals = getWorkLiterals(useLiterals());

    const globalData = useDataContext();
    const workData = globalData && globalData.work;
    const workDataPlaceholder = Array(WORK_EXPERIENCE_PLACEHOLDER_LIST_COUNT).fill(undefined);

    return (
        <div className="d-flex flex-column gap-1rem">
            <SplitContainer title={literals && literals.title} showTitle={true} />
            {
                workData ?
                <WorkTimeline workData={workData} /> :
                <WorkTimeline workData={workDataPlaceholder} />
            }
        </div>
    );
}

export default WorkExperience;