import { DataProvider } from "../contexts/DataContext";
import Body from "../components/projectPage/layout/ProjectLayout";
import Footer from "../components/common/footer/Footer";
import { MediaProvider } from "../contexts/MediaContext";

const ProjectPage = () => {

    return (
        <DataProvider type="projects">
            <MediaProvider type="projects">
                <Body />
                <Footer />
            </MediaProvider>
        </DataProvider>
    )
}

export default ProjectPage;