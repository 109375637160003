import { styled } from "@mui/system"
import ReactPlayer from "react-player";
import PropTypes from 'prop-types';
import { useState } from "react";

const ResponsiveContainer = styled('div')(() => ({
    overflow: "hidden",
    position: "relative",
    height: '100%'
}));

const YoutubeEmbed = ({embedId, LoadingPlaceholder}) => {
    const [ playing, setPlaying ] = useState(false);
    const [ ready, setReady ] = useState(false);

    const playHandle = () => setPlaying(true);
    const stopHandle = () => setPlaying(false);
    const readyHandle = () => setReady(true);

    return (
        <ResponsiveContainer>
            {
                (LoadingPlaceholder && !ready) &&
                <LoadingPlaceholder />
            }
            <ReactPlayer url={`https://www.youtube.com/embed/${embedId}`} 
                width="100%" 
                height="100%" 
                controls={true}
                playing={playing}
                onPlay={playHandle}
                onPause={stopHandle}
                onEnded={stopHandle}
                onReady={readyHandle}
            />
        </ResponsiveContainer>
        
    )
}

YoutubeEmbed.propTypes = {
    embedId: PropTypes.string.isRequired
}

export default YoutubeEmbed;