const DEFAULT_VALUE = undefined;

export function getOtherLiterals(literals)
{
    if(!literals || !literals.other)
    {
        return DEFAULT_VALUE;
    }

    return literals.other;
}

export function getFooterLiterals(literals)
{
    if(!literals || !literals.footer)
    {
        return DEFAULT_VALUE;
    }

    return literals.footer;
}

export function getEducationLiterals(literals)
{
    if(!literals || !literals.education)
    {
        return DEFAULT_VALUE;
    }

    return literals.education;
}

export function getIntroductionLiterals(literals)
{
    if(!literals || !literals.introduction)
    {
        return DEFAULT_VALUE;
    }

    return literals.introduction;
}

export function getProjectsLiterals(literals)
{
    if(!literals || !literals.projects)
    {
        return DEFAULT_VALUE;
    }

    return literals.projects;
}

export function getWorkLiterals(literals)
{
    if(!literals || !literals.work)
    {
        return DEFAULT_VALUE;
    }

    return literals.work;
}

export function getProjectPageLiterals(literals)
{
    if(!literals || !literals.projectPage)
    {
        return DEFAULT_VALUE;
    }

    return literals.projectPage;
}

export function getErrorLiterals(literals)
{
    if(!literals || !literals.error)
    {
        return DEFAULT_VALUE;
    }

    return literals.error;
}

export function getImageViewerLiterals(literals)
{
    if(!literals || !literals.imageViewer)
    {
        return DEFAULT_VALUE;
    }

    return literals.imageViewer;
}