import { Card, CardContent, CardMedia, Typography, Divider, useTheme, Skeleton } from "@mui/material";
import { getPublicAsset, processRichText } from "../../../utils/utils";
import { useViewport } from "../../../hooks/useViewport";
import RedirectionalIconButton from "../../common/buttons/RedirectionalIconButton";
import { useProgressiveImage } from "../../../hooks/useProgressiveImage";

const CertLogo = ({certMedia}) => {
    const theme = useTheme();

    const logoStyle = useViewport({
        xs: {width: "100%", height: 100, backgroundSize: "contain"},
        sm: {width: 200, height: "100%", backgroundSize: "contain"},
        md: {width: "100%", height: 100, backgroundSize: "contain"},
        xl: {width: 200, height: "100%", backgroundSize: "contain"},
    });

    const certImage = useProgressiveImage(certMedia ? getPublicAsset(certMedia.logoPath) : undefined);

    return (
        <>
            {
                certMedia 
                ?
                    certMedia.logoPath &&
                    <div style={{padding: "1rem", backgroundColor: theme.palette.primary.light}}>
                    {
                        certImage ?
                        <CardMedia 
                            sx={logoStyle}
                            image={certImage} /> :
                        <Skeleton sx={logoStyle} />
                    }
                    </div>
                :
                <div style={{padding: "1rem", backgroundColor: theme.palette.primary.light}}>
                    <Skeleton sx={logoStyle} />
                </div>
            }
        </>
    )
}

const CertContent = ({certData, containerClass}) => {
    const Header = () => {
        return (
            <div className="d-flex flex-row jc-space-between">
                {
                    certData ?
                    <div className="d-flex flex-column gap-0_25rem">
                        <Typography fontWeight={700}>{certData.name}</Typography>
                        <Typography fontWeight={700}>{certData.date}</Typography>
                    </div> :
                    <Skeleton width="100%" height="2rem" />
                }
                {
                    certData && certData.links &&
                    <div className={`${containerClass} gap-0_5rem`}>
                        {
                            certData.links.map((link, id) => <RedirectionalIconButton key={id} link={link} />)
                        }
                    </div>
                }
            </div>
        )
    }

    const Body = () => <Typography>{certData ? processRichText(certData.description) : <Skeleton width="100%" height="5rem"/>}</Typography>

    return (
        <CardContent className="flex-1">
            <div className="d-flex flex-column gap-0_5rem">
                <Header />
                <Divider />
                <Body />
            </div>
        </CardContent>
    )
}

const CertificationItem = ({certData, certMedia}) => {    
    const containerClass = useViewport({
        xs: "d-flex flex-column",
        sm: "d-flex flex-row",
        md: "d-flex flex-column",
        xl: "d-flex flex-row"
    });

    return (
        <Card elevation={4}>
            <div className={containerClass}>
                <CertLogo certMedia={certMedia} />
                <CertContent certData={certData} containerClass={containerClass} />
            </div>
        </Card>
    )
}

export default CertificationItem;