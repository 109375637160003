import { createContext } from "react";
import { useJson } from "../hooks/useJson";

export const MediaContext = createContext();

export const MediaProvider = ({ children, type }) => {
    const mediaData = useJson(`${type}-media`);

    return (
        <>
            {
                <MediaContext.Provider value={mediaData}>
                    {children}
                </MediaContext.Provider>
            }
        </>
    )
}