import { Divider, Skeleton, Typography, useTheme } from "@mui/material";

import PhotoAndName from "./PhotoAndName";
import SplitContainer from "../../common/containers/SplitContainer";
import BulletList from "../../common/text/BulletList";

import { useDataContext } from "../../../hooks/useDataContext";
import { useLiterals } from "../../../hooks/useLiterals";
import { useViewport } from "../../../hooks/useViewport";

import { getIntroductionLiterals } from "../../../utils/literalUtils";
import { INTRODUCTION_PLACEHOLDER_BULLET_LIST_COUNT } from "../../../utils/constants";

const IntroductionBody = () => {
    const literals = getIntroductionLiterals(useLiterals());

    const globalData = useDataContext();
    const introData = globalData && globalData.intro;

    const defineClass = useViewport({
        xs: "flex-column",
        sm: "flex-row"
    });

    const theme = useTheme();
    const darkMode = theme.palette.mode === 'dark';

    return (
        <>
            {
                <div className="d-flex flex-column gap-1rem">
                    {
                        literals && introData ?
                        <div className={`d-flex ${defineClass} gap-0_5rem`}>
                            <Typography variant="h4">{literals.Im}</Typography>
                            <Typography variant="h4" color={darkMode ? "primary.light" : "primary"}>{introData.role}</Typography>
                        </div> :
                        <Typography variant="h4">{<Skeleton />}</Typography>
                    }
                    <Divider />
                    <BulletList 
                        texts={introData && introData.texts} 
                        placeholderListCount={INTRODUCTION_PLACEHOLDER_BULLET_LIST_COUNT}
                    />
                </div>
            }
        </>
    );
}

const Introduction = () => {
    
    const headerData = {
        size: 2,
        component: <PhotoAndName />
    };

    const bodyData = {
        size: 10,
        component: <IntroductionBody />
    };

    return (
        <SplitContainer
            headerData={headerData}
            bodyData={bodyData}
        />
    );
}

export default Introduction;