import * as React from 'react';
import { styled, ThemeProvider } from "@mui/material/styles";
import customThemes from '../themes/CustomThemes';
import { ThemeModeContext } from './ThemeModeContext';

const PlainBackground = styled('div')(({theme}) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: -2,
    backgroundColor: theme.palette.background.base
}))

const VignetteBackground = styled('div')(({theme}) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: -1,
    backgroundImage: theme.palette.background.verticalVignette,
    mixBlendMode: 'multiply'
}));

export const CustomThemeProvider = ({children}) => {
    const { lightTheme, darkTheme } = customThemes;
    const [ themeMode, setThemeMode] = React.useState('light');

    const selectedTheme = React.useMemo(() => {
        if(themeMode === 'dark')
        {
            return darkTheme;
        }
    
        return lightTheme;
    }, [themeMode, lightTheme, darkTheme])

    return (
        <ThemeProvider theme={selectedTheme}> 
            <ThemeModeContext.Provider value={{ themeMode, setThemeMode }}>
                <VignetteBackground />
                <PlainBackground />
                {children}
            </ThemeModeContext.Provider>
        </ThemeProvider>
    )
}