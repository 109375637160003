import './App.css';
import { ViewportProvider } from "./contexts/ViewportContext";
import { CustomThemeProvider } from "./contexts/CustomThemeContext";
import MainPage from './pages/MainPage';
import ProjectPage from "./pages/ProjectPage";
import Error404 from "./pages/Error404";
import { Switch, Route } from 'wouter';
import LanguageButton from './components/common/buttons/LanguageButton';
import { LanguageProvider } from './contexts/LanguageContext';
import { LiteralsProvider } from './contexts/LiteralsContext';
import { useViewport } from './hooks/useViewport';

const PageSwitch = () => {
  const lateralMargins = useViewport({
    xs: "",
    sm: "mx-2 mt-2"
  });

  return (
    <div className="d-flex flex-row jc-center">
      <div className={`${lateralMargins} mb-1`} style={{ flex: 1, maxWidth: "1920px" }}>
        <Switch>
          <Route path="/project/:projectName" component={ProjectPage} />

          <Route path="/" component={MainPage} />

          <Route component={Error404} />
        </Switch>
      </div>
    </div>
  )
}

function App() {

  return (
    <LanguageProvider>
      <LiteralsProvider>
        <CustomThemeProvider>
          <ViewportProvider>
            <PageSwitch />
            <LanguageButton />
          </ViewportProvider>
        </CustomThemeProvider>
      </LiteralsProvider>
    </LanguageProvider>
  );
}

export default App;
