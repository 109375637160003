import { useTheme } from "@mui/material";

import { VerticalTimeline } from "react-vertical-timeline-component";
import 'react-vertical-timeline-component/style.min.css';

import WorkTimelineElement from "./WorkTimelineElement";

const WorkTimeline = ({workData}) => {
    const theme = useTheme();

        return (
        <VerticalTimeline
            lineColor={theme.palette.primary.light}
        >
            {
                workData.map((data, id) => WorkTimelineElement(data, id))
            }
        </VerticalTimeline>
    );
}

export default WorkTimeline;