import { IconButton, styled } from "@mui/material";
import { isMobile } from "react-device-detect";

const TransparentIconButton = styled(IconButton)(({theme}) => ({
    opacity: isMobile ? 0.75 : 0.5,
    backgroundColor: theme.palette.button.base,
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    transition: "all 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    pointerEvents: "all",
    '&:hover': {
        opacity: 1,
        backgroundColor: theme.palette.button.hover,
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.3)"
    }
}));

export default TransparentIconButton;