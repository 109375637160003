import { createContext } from "react"
import { useJson } from "../hooks/useJson";
import { useLanguage } from "../hooks/useLanguage";

export const DataContext = createContext();

export const DataProvider = ({ children, type }) => {

    const { language } = useLanguage(); 

    const data = useJson(`${type}-${language}`);

    return (
        <>
            {
                <DataContext.Provider value={data}>
                    {children}
                </DataContext.Provider>
            }
        </>
    )
}