import { styled, Typography } from "@mui/material";
import CopyrightIcon from '@mui/icons-material/Copyright';

import DarkSkeleton from '../other/DarkSkeleton';

import { useViewport } from '../../../hooks/useViewport';
import { useLiterals } from '../../../hooks/useLiterals';

import { getFooterLiterals } from '../../../utils/literalUtils';

// #region Styled components

const FooterPhantom = styled('div')(() => ({
    display: 'block',
    padding: '0.5rem 1rem',
    width: "100%",
    boxSizing: "border-box"
}));

const FooterContainer = styled('div')(({theme}) => ({
    padding: "0.5rem 1rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: "0.5rem",
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.getContrastText(theme.palette.primary.dark),
    bottom: 0,
    left: 0,
    right: 0,
    position: "fixed",
    zIndex: 2
}));

// #endregion Styled components

const FooterContent = () => {
    const literals = getFooterLiterals(useLiterals());

    const layoutClass = useViewport({
        xs: "flex-column",
        md: "flex-row gap-0_5rem"
    });

    const separatorClass = useViewport({
        xs: "d-none",
        md: "d-block"
    });

    return (
        <div className={`d-flex ${layoutClass} full-width`}>
            <div className="d-flex flex-row gap-0_5rem">
                <CopyrightIcon />
                <Typography variant="subtitle1">{literals ? literals.name : <DarkSkeleton width="10rem" />}</Typography>
            </div>
            <div className={separatorClass}>
                <Typography variant="subtitle1">|</Typography>
            </div>
            <Typography variant="subtitle1" noWrap>{literals ? literals.text : <DarkSkeleton width="20rem" />}</Typography>
        </div>
    )
}

const Footer = () => {
    const phantomHeight = useViewport({
        xs: "90px",
        md: "45px"
    });

    return (
        <>
            <FooterPhantom sx={{ height: phantomHeight }} />
            <FooterContainer>
                <FooterContent />
            </FooterContainer>
        </>
    );
}

export default Footer;