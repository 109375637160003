import React from "react";

import { isObject } from "lodash";
import { viewportContext } from "../contexts/ViewportContext";
import { breakpoints } from "../utils/utils";

export const useViewport = (values) =>  {
    const width = React.useContext(viewportContext);
    
    if(!isObject(values))
    {
        return values;
    }

    if(width >= breakpoints.xl && values.xl !== undefined)
    {
        return values.xl;
    }

    if(width >= breakpoints.lg && values.lg !== undefined)
    {
        return values.lg;
    }

    if(width >= breakpoints.md && values.md !== undefined)
    {
        return values.md;
    }

    if(width >= breakpoints.sm && values.sm !== undefined)
    {
        return values.sm;
    }

    if(width >= breakpoints.xs && values.xs !== undefined)
    {
        return values.xs;
    }

    return values;
}