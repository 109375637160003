// #region Placeholder constants

export const PLACEHOLDER_LAYOUT = [
    {
        title: "Introduction",
        component: "Introduction",
        size: 12,
        id: "intro",
        icon: "info"
    },
    {
        title: "Work Experience",
        component: "WorkExperience",
        size: 12,
        id: "work",
        icon: "work"
    },
    {
        title: "Education",
        component: "Certifications",
        size: { "xs": 12, "md": 6},
        id: "certs",
        icon: "education"
    },
    {
        title: "Projects",
        component: "Projects",
        size: { "xs": 12, "md": 6},
        id: "projs",
        icon: "project"
    }
];

export const INTRODUCTION_PLACEHOLDER_BULLET_LIST_COUNT = 3;

export const WORK_EXPERIENCE_PLACEHOLDER_LIST_COUNT = 3;

export const CERTIFICATION_PLACEHOLDER_LIST_COUNT = 2;

export const PROJECTS_PLACEHOLDER_LIST_COUNT = 3;

export const PROJECT_PAGE_PLACEHOLDER_LIST_COUNT = 1;
export const PROJECT_PAGE_PLACEHOLDER_ELEMENT_SIZE = 12;

// #endregion Placeholder constants

// #region Styling

// #region Responsive objects

export const ICON_SIZES = {
    xs: "medium",
    sm: "large"
};

export const ICON_SIZES_PX = {
    xs: "24px",
    sm: "35px"
}

export const ICON_SIZES_REM = {
    xs: "1.5rem",
    sm: "2.1875rem"
}

// #endregion Responsive objects

// #region Absolute positions styles

export const POSITION_STYLES = {
    "top": { top: 0, width: "100%" },
    "bottom": { bottom: 0, width: "100%" },

    "left": { left: 0, height: "100%" },
    "right": { right: 0, height: "100%" },

    "top-left": { top: 0, left: 0 },
    "top-right": { top: 0, right: 0 },

    "bottom-left": { bottom: 0, left: 0 },
    "bottom-right": { bottom: 0, right: 0 }
}

export const DEFAULT_POSITION = "top-left";

// #endregion Absolute positions styles

// #region Flex orientations classes

export const ORIENTATION_CLASSES = {
    "horizontal": "flex-row",
    "vertical": "flex-column"
}

export const DEFAULT_ORIENTATION = "horizontal";

// #endregion Flex orientations classes

// #region Padding classes

export const DEFAULT_PADDING = "px-2 py-4";

// #endregion Padding classes

// #endregion Styling

// #region Languages

export const FLAG_PATH = "/flags";

export const FLAG_EXT = "svg"

export const LANGUAGES = [
    "EN",
    "ES"
];

// #endregion Languages