import { Grid2 as Grid } from "@mui/material";
import { useRoute } from "wouter";

import MediaCarousel from "../containers/MediaCarousel"
import ProjectContainer from "../containers/ProjectContainer"
import NavDrawer from '../../common/navigation/NavDrawer';
import HomeButton from "../../common/buttons/HomeButton";
import ProjectSetTitle from "../containers/ProjectSetTitle";

import { useDataContext } from "../../../hooks/useDataContext";
import { useMediaContext } from "../../../hooks/useMediaContext";

import { 
    PROJECT_PAGE_PLACEHOLDER_ELEMENT_SIZE, 
    PROJECT_PAGE_PLACEHOLDER_LIST_COUNT 
} from "../../../utils/constants";

const ProjectElement = ({projectData, projectMedia}) => {
    const elementSize = projectData ? projectData.size : PROJECT_PAGE_PLACEHOLDER_ELEMENT_SIZE;

    return (
        <div className="d-flex flex-column gap-1rem full-width">
            <Grid size={elementSize}>
                <div className="full-height">
                    <ProjectContainer projectData={projectData} projectMedia={projectMedia} />
                </div>
            </Grid>
            {
                (!projectMedia || projectMedia.carousel) &&
                <Grid size={elementSize}>
                    <MediaCarousel 
                        media={projectMedia && projectMedia.carousel} 
                        captions={projectData && projectData.carouselCaptions} 
                    />
                </Grid>
            }
        </div>
    )
}

const ProjectLayout = () => {

    function getProjectData(projectsData, projectId)
    {
        if(!projectsData || !projectsData.projectSets)
        {
            return null;
        }

        const projectSet = projectsData.projectSets.find((projectSet) => projectSet.id === projectId);

        return projectSet;
    }

    const projectsData = useDataContext();
    const projectsMedia = useMediaContext();
    const [match, params] = useRoute("/project/:projectId");

    if(!match)
    {
        return null;
    }

    const projectSetData = projectsData && getProjectData(projectsData, params.projectId);
    const projectSetMedia = projectsMedia && getProjectData(projectsMedia, params.projectId);

    const placeholderList = Array(PROJECT_PAGE_PLACEHOLDER_LIST_COUNT).fill(undefined);
    const mapProjElement = (projectData, id) => 
        <ProjectElement key={id} projectData={projectData} projectMedia={projectSetMedia && projectSetMedia.projects[id]} />

    return (
        <>
            <HomeButton />
            <NavDrawer navElements={projectsData.projectSets} scrollNavigation={false} />
            <div className='d-flex flex-column gap-1rem'>
                {
                    (!projectSetData || projectSetData.showTitle) &&
                    <ProjectSetTitle title={projectSetData && projectSetData.title} />
                }
                {
                    <Grid container spacing={2}>
                    {
                        projectSetData ?
                        projectSetData.projects.map(mapProjElement) :
                        placeholderList.map(mapProjElement)
                    }
                    </Grid>
                }
            </div>
        </>
    )

}

export default ProjectLayout;