import Grid from "@mui/material/Grid2"

import NavDrawer from '../../common/navigation/NavDrawer';

import Introduction from "../intro/Introduction";
import Certifications from "../education/Certifications";
import WorkExperience from "../work/WorkExperience";
import Projects from "../projects/Projects";

import { useDataContext } from "../../../hooks/useDataContext";

import { PLACEHOLDER_LAYOUT } from "../../../utils/constants";

const componentTypes = {
    "Introduction": Introduction,
    "WorkExperience": WorkExperience,
    "Certifications": Certifications,
    "Projects": Projects
}

const GridElement = (gridElement, id) =>
{
    const Component = componentTypes[gridElement.component];
    return (
        <Grid key={id} size={gridElement.size}>
            <div id={gridElement.id} className="full-height">
                <Component/>
            </div>
        </Grid>
    )
}

const NavLayout = () => {
    const globalData = useDataContext();
    const layout = globalData && globalData.layout;

    return (
        <>
            {
                layout ?
                <>
                    <NavDrawer navElements={layout} scrollNavigation={true} />
                    <Grid container spacing={2}>
                        {
                            layout.map((element, id) => GridElement(element, id))
                        }
                    </Grid>
                </> :
                <Grid container spacing={2}>
                    {
                        PLACEHOLDER_LAYOUT.map((element, id) => GridElement(element, id))
                    }
                </Grid>
            }
        </>
    )
}

export default NavLayout;