import { ExpandLess, ExpandMore } from "@mui/icons-material"
import { IconButton } from "@mui/material";

const ExpandingArrow = ({size, color, expanded, setExpanded}) => {
    return (
        <div sx={{color}}>
            <IconButton color='inherit' onClick={() => setExpanded(!expanded)}>
                {
                    expanded ? <ExpandLess fontSize={size} /> : <ExpandMore fontSize={size} />
                }
            </IconButton>
        </div>
    )
}

export default ExpandingArrow;