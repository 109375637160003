import { Tooltip } from "@mui/material";
import PropTypes from 'prop-types';

import TransparentIconButton from "./TransparentIconButton";

import { useViewport } from "../../../hooks/useViewport";

import { 
    DEFAULT_ORIENTATION, 
    DEFAULT_PADDING, 
    DEFAULT_POSITION, 
    ORIENTATION_CLASSES, 
    POSITION_STYLES 
} from "../../../utils/constants";

const FloatingButton = ({button}) => {
    return (
        <div className="auto-pointer-events">
            <Tooltip title={button.tooltip}>
                <TransparentIconButton onClick={button.onClick}>
                    {button.component}
                </TransparentIconButton>
            </Tooltip>
        </div>
    )
}

const FloatingButtonSet = ({buttons, position = DEFAULT_POSITION, orientation = DEFAULT_ORIENTATION, paddingClass = DEFAULT_PADDING}) => 
{
    const positionStyle = POSITION_STYLES[position];
    const orientationClass = ORIENTATION_CLASSES[orientation];
    const buttonPadding = useViewport(paddingClass);

    return (
        <div className="fixed-position z-index-5 no-pointer-events" style={positionStyle}>
            <div className={`d-flex ${orientationClass}  jc-center ai-center full-height ${buttonPadding} borderBox gap-1rem`}>
                {
                    buttons.map((button, index) => <FloatingButton key={index} button={button} />)
                }
            </div>
        </div>
    )
}

FloatingButtonSet.propTypes = {
    buttons: PropTypes.arrayOf(PropTypes.shape({
            component: PropTypes.node.isRequired,
            onClick: PropTypes.func.isRequired,
            tooltip: PropTypes.string
        })).isRequired,
    position: PropTypes.oneOf(Object.keys(POSITION_STYLES)),
    orientation: PropTypes.oneOf(Object.keys(ORIENTATION_CLASSES)),
    paddingClass: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({
        xs: PropTypes.string,
        sm: PropTypes.string,
        md: PropTypes.string,
        lg: PropTypes.string,
        xl: PropTypes.string
    })])
}

export default FloatingButtonSet;