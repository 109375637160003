import { Skeleton, styled, Typography, useTheme } from "@mui/material";
import { INTRODUCTION_PLACEHOLDER_BULLET_LIST_COUNT } from "../../../utils/constants";
import { processRichText } from "../../../utils/utils";
import { Navigation } from "@mui/icons-material";

const RotatedNavigation = styled(Navigation)(({theme}) => ({
    transform: "rotate(90deg)",
    color: theme.palette.icon.main
}));

const ArrowBullet = () => {
    const theme = useTheme();
    const darkMode = theme.palette.mode === 'dark';

    return (
        <RotatedNavigation color={darkMode ? theme.palette.primary.light : theme.palette.primary.main} />
    )
}

const BulletListItem = ({text}) => {
    const textClass = text ? "" : "flex-1";

    return (
        <div className="d-flex flex-row gap-0_5rem">
            <ArrowBullet />
            <Typography className={textClass}>{text ? processRichText(text) : <Skeleton />}</Typography>
        </div>
    )
}

const BulletList = ({texts, placeholderListCount = INTRODUCTION_PLACEHOLDER_BULLET_LIST_COUNT}) => {
    const placeholderList = Array(placeholderListCount).fill(undefined);
    const mapTexts = (text, key) => <BulletListItem key={key} text={text} />;

    return (
        <div className="d-flex flex-column gap-1_5rem">
        {
            texts ?
            texts.map(mapTexts) :
            placeholderList.map(mapTexts)
        }
        </div>
    );
}

export default BulletList;