import { Paper, Skeleton, styled, Typography } from "@mui/material";

import { useViewport } from "../../../hooks/useViewport";

const TitleContainer = styled(Paper, {
    shouldForwardProp: (prop) => prop !== "borderRadius"
})(({theme, borderRadius}) => ({
    backgroundColor: theme.palette.primary.darker,
    width: 'fit-content',
    padding: '1rem 1.5rem',
    color: theme.palette.getContrastText(theme.palette.primary.dark),
    borderRadius: borderRadius,
    boxShadow: theme.shadows[4]
}));

const ProjectSetTitle = ({title}) => {
    const containerBorderRadius = useViewport({
        xs: '0px 10px 0px 0px',
        sm: '10px 10px 0px 10px'
    })

    return (
        <TitleContainer borderRadius={containerBorderRadius}>
            <Typography variant="h3">{title ? title : <Skeleton width="20rem" />}</Typography>
        </TitleContainer>
    )
}

export default ProjectSetTitle;