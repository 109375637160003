import FloatingButtonSet from "./FloatingButtonSet";

import { useLanguage } from "../../../hooks/useLanguage";
import { useLiterals } from "../../../hooks/useLiterals";
import { useViewport } from "../../../hooks/useViewport";

import { getOtherLiterals } from "../../../utils/literalUtils";
import { FLAG_EXT, FLAG_PATH, ICON_SIZES_PX, LANGUAGES } from "../../../utils/constants";
import LazyImage from "../other/LazyImage";
import { getPublicAsset } from "../../../utils/utils";
import { Skeleton } from "@mui/material";

const LanguageButton = () => {
    const literals = getOtherLiterals(useLiterals());
    const iconSize = useViewport(ICON_SIZES_PX);

    const { language, setLanguage } = useLanguage();

    const getOppositeLanguage = (language) => {
        if(LANGUAGES[0] === language)
        {
            return LANGUAGES[1];
        }
        else
        {
            return LANGUAGES[0];
        }
    }

    const onClick = () => {
        setLanguage(getOppositeLanguage(language));
    }

    const Flag = () => {
        const flagImageSrc = `${FLAG_PATH}/${getOppositeLanguage(language)}.${FLAG_EXT}`;
        const imageSize = { height: iconSize, width: iconSize }

        const FlagSkeleton = () => <Skeleton variant="circular" sx={imageSize} />

        return (
            <LazyImage src={getPublicAsset(flagImageSrc)} alt="lang" sx={imageSize} LoadingPlaceholder={FlagSkeleton} />    
        )
    }

    const button = [
        {
            component: <Flag />,
            onClick: onClick,
            tooltip: literals && literals.changeLanguage
        }
    ]

    const paddingClass = useViewport({
        xs: "p-2",
        sm: "px-2 py-4"
    });

    return (
        <FloatingButtonSet buttons={button} position="top-right" paddingClass={paddingClass} />
    );
}

export default LanguageButton;