import { Divider, Skeleton, styled, Typography } from "@mui/material";

import SplitContainer from "../../common/containers/SplitContainer"

import { useViewport } from "../../../hooks/useViewport";
import { useProgressiveImage } from "../../../hooks/useProgressiveImage";

import { getLinkObj, getPublicAsset, processRichText } from "../../../utils/utils";

const MainImage = styled('img')(({theme, withBorder}) => ({
    overflow: 'hidden',
    borderRadius: withBorder ? theme.shape.borderRadius : 0,
    border: withBorder ? `solid 3px ${theme.palette.primary.main}` : ""
}))

const Header = ({projectData, projectMedia}) => {

    const HeaderImage = () => {
        const projImage = useProgressiveImage(projectMedia ? getPublicAsset(projectMedia.mainImage.url): undefined);

        let imageHeight = useViewport(projectMedia && projectMedia.mainImage.height);
        if(!imageHeight)
        {
            imageHeight = "100px";
        }

        let imageWidth = useViewport(projectMedia && projectMedia.mainImage.width);
        if(!imageWidth)
        {
            imageWidth = "auto";
        }

        const imageStyle = { height: imageHeight, width: imageWidth };
        const ImagePlaceholder = () => <Skeleton sx={{ height: "100px", width: "100%"}} />

        return (
            <>
                {
                    projectMedia
                    ?
                        projectMedia.mainImage &&
                        projImage ?
                        <MainImage 
                            src={projImage} 
                            alt="projectImage" 
                            style={imageStyle} 
                            withBorder={projectMedia.mainImage.withBorder}
                        />
                        :
                        <ImagePlaceholder />
                    :
                    <ImagePlaceholder />
                }
            </>
        )
    }

    const HeaderRoles = () => {
        const rolesTextVariant = useViewport({
            xs: "h6",
            lg: "subtitle1"
        });
    
        const rolesSkeletonStyle = { width: "10rem", height: "5rem" }

        return (
            <div className="d-flex flex-column gap-0_5rem">
            {
                projectData ?
                projectData.roles.map((role, id) => (
                    <Typography key={id} variant={rolesTextVariant} className="text-align-center">{role}</Typography>
                )) :
                <Skeleton sx={rolesSkeletonStyle} />
            }
            </div>
        );
    }

    

    return (
        <div className="d-flex flex-column ai-center gap-1rem">
            <HeaderImage />
            <HeaderRoles />
        </div>
    );
};

const Body = ({projectData}) => {

    const linkObj = getLinkObj(projectData);

    return (
        <div className="d-flex flex-column gap-0_75rem">
            <Typography variant="subtitle1">{projectData ? projectData.date : <Skeleton />}</Typography>
            <Divider />
            <div className="d-flex flex-column gap-0_5rem">
                {
                    projectData ?
                    projectData.texts.map((textSet, id) => (
                        <Typography key={id}>
                            {processRichText(textSet, linkObj)}
                        </Typography>
                    )) :
                    <Skeleton height="10rem" />
                }
            </div>
        </div>
    );
};

const ProjectContainer = ({projectData, projectMedia}) => {
    const headerData = {
        size: 1,
        component: <Header projectData={projectData} projectMedia={projectMedia} />
    };

    const bodyData = {
        size: 7,
        component: <Body projectData={projectData} />
    };

    return (
        <SplitContainer
            title={projectData && projectData.name}
            showTitle={true}
            headerData={headerData}
            bodyData={bodyData}
            contentBreakpoint={"lg"}
        />
    )
}

export default ProjectContainer;