import { DataProvider } from "../contexts/DataContext";
import Body from '../components/mainPage/layout/MainLayout';
import Footer from "../components/common/footer/Footer";
import { MediaProvider } from "../contexts/MediaContext";

const MainPage = () => {
  return (
      <DataProvider type="general">
        <MediaProvider type="general">
          <Body />
          <Footer />
        </MediaProvider>
      </DataProvider>
    )
  }

export default MainPage;