import { IconButton } from "@mui/material";

import { getIconFromName } from "../../../utils/iconUtils";

const RedirectionalIconButton = ({link, size}) => {

    const onClickIconButton = () => {
        window.open(link.url, "_blank");
    }

    return (
        <IconButton 
            size={size} 
            sx={{ height: "fit-content" }} 
            onClick={onClickIconButton}
        >
            {
                getIconFromName(link.icon)
            }
        </IconButton>
    )
}

export default RedirectionalIconButton;