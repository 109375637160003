import { Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import DarkSkeleton from "../other/DarkSkeleton";
import LeftBorderContainer from "./LeftBorderContainer";

import { useViewport } from "../../../hooks/useViewport";

// #region Styled components

const TitleContainer = styled('div')(({theme}) => ({
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
    padding: '0.5rem 1rem',
}));

const HeaderSplit = styled('div')(({theme, flexSize, padding}) => ({
    backgroundColor: theme.palette.primary.light,
    padding: padding,
    flex: flexSize
}));

const BodySplit = styled(Paper, {
    shouldForwardProp: (prop) => 
        prop !== "flexSize" && prop !== "padding"
})(({flexSize, padding}) => ({
    padding: padding,
    flex: flexSize,
    borderRadius: 0,
    boxShadow: "none"
}))

const CustomPaper = styled(Paper)(({theme}) => ({
    boxSizing: 'border-box',
    boxShadow: theme.shadows[0],
    display: "flex",
    flexDirection: "column",
    flex: 1
}))

// #endregion Styled components

const SplitContainer = ({title, showTitle = false, headerData, bodyData, contentBreakpoint = "md"}) => {

    const Title = () => {
        return (
            <>
            {
                showTitle && 
                <TitleContainer>
                    <Typography variant="h5">{title ? title : <DarkSkeleton width="15rem" />}</Typography>
                </TitleContainer>
            }
            </>
        )
    }

    const Content = () => {
        const viewportObj = {
            xs: "d-flex flex-column"
        }
        viewportObj[contentBreakpoint] = "d-flex flex-row"

        const contentClass = useViewport(viewportObj);
        const contentPadding = useViewport({
            xs: "1.5rem",
            sm: "2rem"
        });

        return (
            <>
            {
                (headerData || bodyData) &&
                <CustomPaper>
                    <div className={`${contentClass} full-height`}>
                        {
                            headerData &&
                            <HeaderSplit flexSize={headerData.size} padding={contentPadding}>
                                {headerData.component}
                            </HeaderSplit>
                        }
                        {
                            bodyData &&
                            <BodySplit flexSize={bodyData.size} padding={contentPadding}>
                                {bodyData.component}
                            </BodySplit>
                        }
                    </div>
                </CustomPaper>
            }
            </>
        )
    }

    return (
        <LeftBorderContainer>
            <Title />
            <Content />
        </LeftBorderContainer>
    )
}

export default SplitContainer;