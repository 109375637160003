import { Link } from "@mui/material";

export const breakpoints = {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1200,
    xl: 1536
}

export const getPublicAsset = (path) =>
{
    return `${process.env.PUBLIC_URL}${path}`
}

export const processRichText = (richText, linkObj) => {

    const processText = (textData, id) => 
    {
        let textClass = "";
        let textWeight = undefined;
        if(textData.boldText)
        {
            textClass = "bold-text";
            textWeight = 700;
        }
    
        if(textData.link)
        {
            return <Link 
                key={id} 
                href={textData.linkUrl} 
                fontWeight={textWeight}
                target="_blank"
                rel="noreferrer"
            >
                {textData.text}
            </Link>
        }
    
        return <span key={id} className={textClass}>{textData.text}</span>
    }

    function generateTextBlocks(richText)
    {
        let textBlocks = [];

        let currentString = "";
        let boldText = false;
        let link = false;
        let backslash = false;
        for(let i = 0; i < richText.length; i++)
        {
            if(richText[i] === "\\")
            {
                backslash = true;
            }
            else if((richText[i] === "$" || richText[i] === "@") && !backslash)
            {
                if(currentString.length > 0)
                {
                    textBlocks = [...textBlocks, { 
                        text: currentString, 
                        boldText: boldText, 
                        link: link, 
                        linkUrl: linkObj && link ? linkObj.urls[linkObj.pointer] : undefined
                    }];
                    currentString = "";
                }

                if(richText[i] === "$")
                {
                    boldText = !boldText;
                }

                if(richText[i] === "@")
                {
                    if(linkObj && link)
                    {
                        linkObj.pointer++;
                    }
                    link = !link;
                }

                continue;
            }
            else 
            {
                backslash = false;
                currentString = `${currentString}${richText[i]}`;
            }
        }

        textBlocks = [...textBlocks, { text: currentString, boldText: boldText }];

        return textBlocks;
    }

    return (
        <>
            {
                generateTextBlocks(richText).map((textData, id) => processText(textData, id))
            }
        </>
    )
}

export const getLinkObj = (data) => {
    if(!data || !data.links)
    {
        return undefined;
    }

    return { urls: data.links, pointer: 0 };
}

