import { Button } from "@mui/material";

import { useLocation } from "wouter";
import { useLiterals } from "../../../hooks/useLiterals";

import { getOtherLiterals } from "../../../utils/literalUtils";

const MoreInfoButton = ({route}) => {
    const literals = getOtherLiterals(useLiterals());
    const [, setLocation] = useLocation();

    const onClick = () => {
        setLocation(route);
    }

    return (
        <Button color='secondary' variant="contained" onClick={onClick}>
            {literals && literals.moreInfo}
        </Button>
    );
}

export default MoreInfoButton;