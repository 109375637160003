import { 
    Public, 
    YouTube, 
    SportsEsports,
    Help,
    Info,
    Work,
    School,
    Build,
    PictureAsPdf,
    Web,
    GitHub,
    Psychology
} from "@mui/icons-material"

const icons = {
    "web": Public,
    'youtube': YouTube,
    'game': SportsEsports,
    'help': Help,
    'info': Info,
    'work': Work,
    'education': School,
    'project': Build,
    'pdf': PictureAsPdf,
    'frontEnd': Web,
    'github': GitHub,
    "ai": Psychology
}

export const getIconFromName = (iconName) => {
    const Result = icons[iconName];

    if(Result === undefined)
    {
        return <Help fontSize="inherit" />
    }

    return <Result fontSize="inherit" />;
}