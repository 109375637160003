import { styled, Switch } from "@mui/material"

import { useThemeMode } from "../../../hooks/useThemeMode";

const ColoredSwitch = styled(Switch)(({theme}) => ({
    "& .MuiButtonBase-root": {
        color: theme.palette.icon.main
    }
}))

const DarkModeToggle = () => {
    const { themeMode, setThemeMode } = useThemeMode();

    const onChange = (event) => {
        if(event.target.checked) {
            setThemeMode("dark");
        } else {
            setThemeMode("light");
        }
    }

    return (
        <ColoredSwitch onChange={onChange} checked={themeMode === "dark"} />
    )
}

export default DarkModeToggle;